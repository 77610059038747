<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title w-100 row">
                <div class="col-10">
                  {{ atendimento.id ? "Editar" : "Novo" }} Atendimento
                </div>
                <div class="col-2 d-flex justify-content-end">
                  <h2 style="color: blue; margin-top: 5px; margin-right: -35px">
                    <span
                      id="timer"
                      :class="horas > 0 || minutos > 0 ? 'piscar' : ''"
                    ></span>
                    <i
                      :class="minutos > 0 ? 'piscar' : ''"
                      class="fas fa-clock fa-spin ml-1 mr-2"
                      style="font-size: 24px; color: blue"
                    ></i>
                  </h2>
                </div>
              </div>
            </div>
            <div class="card-body">
              <hr />
              <div class="row mb-3">
                <div class="col-md-6">
                  <toggle-button
                    v-model="atendimento.flag_nao_identificado"
                    color="#15488A"
                    :height="25"
                  />
                  Paciente não identificado
                </div>
                <div class="col-md-5" v-if="!atendimento.flag_nao_identificado">
                  <toggle-button
                    v-model="nome_social"
                    color="#15488A"
                    :height="25"
                  />
                  Paciente usa nome social
                </div>
              </div>
              <div class="row" v-if="atendimento.flag_nao_identificado">
                <div class="col-md-12">
                  <label>* Descrição do paciente não identificado</label>
                  <textarea
                    type="text"
                    class="form-control"
                    v-model="atendimento.paciente.nome_paciente"
                  ></textarea>
                </div>
              </div>
              <div class="row" v-show="!atendimento.flag_nao_identificado">
                <div class="col-md-6">
                  <label>CPF</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="atendimento.paciente.cpf"
                    v-mask="'999.999.999-99'"
                    v-on:keyup="buscarCPF"
                  />
                </div>
                <div class="col-md-6">
                  <label>Número do CNS</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="atendimento.paciente.cns"
                    v-on:keyup="buscarCNS"
                  />
                </div>
                <div :class="'mt-1 col-md-' + (nome_social ? '5' : '10')">
                  <label>* Nome do Paciente</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="atendimento.paciente.nome_paciente"
                  />
                </div>
                <div class="col-md-5 mt-1" v-if="nome_social">
                  <label>* Nome Social</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="atendimento.paciente.nome_social"
                  />
                </div>
                <div class="col-md-2 mt-1">
                  <label>Data de Nascimento</label>
                  <input
                    type="date"
                    class="form-control"
                    v-model="atendimento.paciente.data_nascimento"
                    max="9999-12-31"
                  />
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-md-2" v-if="atendimento.flag_nao_identificado">
                  <label>* Faixa Etária</label>
                  <select
                    class="form-control"
                    v-model="atendimento.paciente.faixa_etaria"
                  >
                    <option value=""></option>
                    <option value="1">Neonatal</option>
                    <option value="2">Bebê</option>
                    <option value="3">Criança</option>
                    <option value="4">Adulto</option>
                  </select>
                </div>
                <div class="col-md-2">
                  <label>Sexo</label>
                  <select
                    class="form-control"
                    v-model="atendimento.paciente.sexo"
                  >
                    <option value="M">Masculino</option>
                    <option value="F">Feminino</option>
                    <option value="O">Outro</option>
                  </select>
                </div>
                <div
                  class="col-md-2"
                  v-show="!atendimento.flag_nao_identificado"
                >
                  <label>Telefone</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="atendimento.paciente.telefone"
                    v-mask="'(99) 99999-9999'"
                  />
                </div>
                <div class="col-md-2">
                  <label>* Prioridade</label>
                  <select
                    class="form-control"
                    v-model="atendimento.priority_id"
                  >
                    <option
                      v-for="prioridade in prioridades"
                      :key="prioridade.id"
                      :value="prioridade.id"
                    >
                      {{ prioridade.title }}
                    </option>
                  </select>
                </div>
                <div class="col-md-2">
                  <label>Origem</label>
                  <select class="form-control" v-model="atendimento.origin_id">
                    <option
                      v-for="origin in origins"
                      :key="origin.id"
                      :value="origin.id"
                    >
                      {{ origin.title }}
                    </option>
                  </select>
                </div>
                <div class="col-md-2">
                  <label>* Senha Atendida</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="atendimento.senha"
                    :disabled="atendimento.id != null"
                  />
                </div>
                <div class="col-md-2">
                  <label>* Entrada na Unidade</label>
                  <input
                    type="datetime-local"
                    class="form-control"
                    v-model="atendimento.data_entrada"
                    :disabled="atendimento.id != null"
                    v-on:change="setTimer()"
                    max="9999-12-31T23:59"
                  />
                </div>
                <div class="col-md-3 mt-2">
                  <button
                    class="btn btn-add"
                    style="margin-top: 5px"
                    v-if="!atendimento.id"
                  >
                    Gerar Senha no SIGAP
                  </button>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12">
                  <span class="required_fields mt-2">
                    <span class="required">*</span>
                    <strong>Campos obrigatórios</strong>
                  </span>
                  <div class="block text-right">
                    <router-link to="/triage" class="btn btn-back">
                      <i class="fa fa-arrow-left"></i> Voltar
                    </router-link>
                    <a class="btn btn-add ml-1" @click="save">
                      Salvar
                      <i class="fa fa-save"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      atendimento: {
        paciente: {
          nome_paciente: "",
          cpf: "",
          cns: "",
          data_nascimento: "",
          sexo: "",
        },
        priority_id: "1",
        origin_id: "",
        flag_nao_identificado: false,
      },
      naoLocalizado: false,
      prioridades: [],
      origins: [],
      nome_social: false,
      tempo: 0,
      minutos: 0,
      segundos: 0,
      horas: 0,
    };
  },
  components: {},
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "atendimentos";

      if (self.atendimento.id) {
        self.atendimento._method = "PATCH";
        api += "/" + self.atendimento.id;
      }

      // self.atendimento.data_nascimento = moment(
      //   self.atendimento.data_nascimento
      // ).format("YYYY-MM-DD");

      axios
        .post(api, self.atendimento)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );

          clearInterval(self.interval);

          if (self.atendimento.id) {
            this.$router.push("/triage/flowchart/" + self.atendimento.id);
          } else {
            this.$router.push("/triage/flowchart/" + response.data.id);
          }
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    buscarCNS: function () {
      var self = this;

      self.naoLocalizado = false;

      $.getJSON(
        "http://sigap.it4d.com.br/index.php/pesquisaSus?num_sus=" +
          self.atendimento.paciente.cns,
        function (data) {
          if (data.erro == "Código SUS não encontrado") {
            return;
          }
          self.atendimento.paciente.nome_paciente = data.nome;
          self.atendimento.paciente.data_nascimento = moment(
            data.dataNascimento
          ).format("YYYY-MM-DD");
          self.atendimento.paciente.sexo = data.sexo;
          self.atendimento.paciente.cpf = data.cpf;
        }
      );
    },
    buscarCPF: function () {
      const self = this;

      if (self.atendimento.paciente.cpf.includes("_")) {
        return;
      }

      self.naoLocalizado = false;

      self.$loading(true);

      var cpf = self.atendimento.paciente.cpf.replace(/\.|\-/g, "");

      let api = self.$store.state.api + "atendimento/get-cpf";
      axios
        .post(api, { cpf: cpf })
        .then((response) => {
          var data = response.data;

          if (data.erro == "CPF não encontrado") {
            self.$loading(false);
            self.$message("Atenção!", "CPF não encontrado", "warning");
            return;
          }

          self.atendimento.paciente.nome_paciente = data.nome;
          self.atendimento.paciente.data_nascimento = moment(
            data.dataNascimento
          ).format("YYYY-MM-DD");
          self.atendimento.paciente.sexo = data.sexo;
          self.atendimento.paciente.cpf = data.cpf;
          self.atendimento.paciente.cns = data.cnsFederalDefinitivo;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message("Atenção!", "CPF não encontrado", "warning");
          self.$loading(false);
        });
    },
    getEntity: function (id) {
      const self = this;
      let api =
        self.$store.state.api + "atendimentos/" + id + "?with[]=paciente";

      self.$loading(true);

      axios
        .get(api, self.atendimento)
        .then((response) => {
          self.atendimento = response.data.data[0];
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },

    getPrioridade: function () {
      const self = this;
      let api = self.$store.state.api + "priorities?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.prioridades = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getOrigin: function () {
      const self = this;
      let api = self.$store.state.api + "origins?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.origins = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    countdown: function () {
      this.segundos++;

      if (this.segundos >= 60) {
        this.minutos = Math.trunc(this.segundos / 60);
      }

      if (this.minutos >= 60) {
        this.horas = Math.trunc(this.minutos / 60);
      }

      let tempo =
        this.horas.toString().padStart(2, "0") +
        ":" +
        (this.minutos % 60).toString().padStart(2, "0") +
        ":" +
        (this.segundos % 60).toString().padStart(2, "0");

      $("#timer").html(tempo);
    },
    setTimer: function () {
      let now = moment();
      this.segundos = now.diff(this.atendimento.data_entrada, "seconds");
    },
  },
  mounted() {
    const self = this;

    self.getPrioridade();
    self.getOrigin();

    //self.atendimento.data_entrada = moment().format("YYYY-MM-DD HH:mm:ss");

    let id = self.$route.params.id;
    if (id) {
      self.getEntity(id);
    }

    self.setTimer();
    self.interval = setInterval(this.countdown, 1000);
  },
  created() {},
};
</script>

<style></style>
